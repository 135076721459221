

















import { Component, Vue, Watch } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import { Route } from 'vue-router'

@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    Title,
    ProgressLine
  }
})
export default class PreTest extends Vue {
  private advancement = 0
  private hideStepCount = true

  private mounted (): void {
    if (window.innerWidth < this.$data.mobileWidth) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  private goBack (): void {
    switch (this.$route.name) {
      case 'pre test 4':
        this.$router.push('/pre-test/3')
        break
      case 'pre test 3':
        this.$router.push('/pre-test/2')
        break
      case 'pre test 2':
        this.$router.push('/pre-test/1')
        break
      case 'pre test 1':
        this.$router.push('/pre-test/video')
        break
      default:
        this.$router.push('/welcome')
        break
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  onRouteChange (newRoute: Route): void {
    this.advancement = newRoute.meta.advancement
    this.hideStepCount = newRoute.meta.hideStepCount || false
  }

  private destroyed (): void {
    if (window.innerWidth < this.$data.mobileWidth) this.$root.$off('mobile-go-back')
  }
}
